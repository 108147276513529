<template>
  <div>
    <div class="navbar">
      <img
        src="@/assets/images/back_black.png"
        class="navbar_back"
        @click="toBack"
      />
      <span class="navbar_title">商家入驻指南</span>
      <span></span>
    </div>
    <div
      class="content"
      style="background: linear-gradient(180deg, #03c681, #1caf8e 100%)"
    >
      <img src="@/assets/images/page_1_header.png" alt="" />
      <div style="margin-top: -45.3333vw">
        <img src="@/assets/images/page_1_1.png" alt="" />
        <img src="@/assets/images/page_1_2.png" alt="" @click="toAuth" />
        <img
          src="@/assets/images/page_1_3.png"
          alt=""
          @click="openMarketPopup"
        />
        <img
          src="@/assets/images/page_1_4.png"
          alt=""
          @click="toCreateProduct"
        />
        <img src="@/assets/images/page_1_5.png" alt="" />
        <div class="avtive_footer">
          <img src="@/assets/images/footer.png" alt="" />
        </div>
      </div>
    </div>

    <div class="mask" v-if="isShow">
      <div class="box">
        <div class="top">
          <span> 请选择 </span>
          <img
            @click="isShow = false"
            style="width: 7vw; height: 7vw"
            src="@/assets/images/close_black.png"
          />
        </div>
        <div class="bottom">
          <a class="item" @click="toOrigin">
            <img
              style="width: 21.3vw; height: 21.3vw; margin-bottom: 3vw"
              src="@/assets/images/10377@2x.png"
            />
            <div>原地产</div>
          </a>
          <a class="item" @click="toMarket">
            <img
              style="width: 21.3vw; height: 21.3vw; margin-bottom: 3vw"
              src="@/assets/images/10376@2x.png"
            />
            <div>批发市场</div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      isShow: false,
      isShowPopup: false,
      email: 'gnsl.service@suzi.com.cn',
      baseUrl: process.env.VUE_APP_APP_URL,
      isUniApp: false,
      envLoading: true
    }
  },
  created () {
    this.isUniApp = !!this.$route.query.auth_token
    document.addEventListener('UniAppJSBridgeReady', () => {
      this.envLoading = false
    })
  },
  methods: {
    toOrigin () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateTo({
            url: '/pages/originMarket/origin/select'
          })
        }
      } else {
        window.location.href = `${this.baseUrl}/origin_place_html/select`
      }
    },
    toMarket () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateTo({
            url: '/pages/originMarket/market/select'
          })
        }
      } else {
        window.location.href = `${this.baseUrl}/profession_market_html/select`
      }
    },
    toAuth () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateTo({
            url: '/pages/price/storeSetting/enterpriseCertification'
          })
        }
      } else {
        window.location.href = `${this.baseUrl}/authentication_info/enterprise_certification_html`
      }
    },
    toCreateProduct () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateTo({
            url: '/pages/product/productInfo/productStock'
          })
        }
      } else {
        window.location.href = `${this.baseUrl}/product_info/product_stock`
      }
    },
    openMarketPopup () {
      this.isShow = true
    },
    toBack () {
      if (this.isUniApp) {
        if (this.envLoading) {
          this.$toast('环境初始化中，请稍后')
        } else {
          window.uni.navigateBack({
            delta: 1
          })
        }
      } else {
        window.history.back()
      }
    }
    // copy () {
    //   this.$copyText(this.email)
    //   this.$toast('复制成功')
    // }
  }
}
</script>

<style lang="scss" scoped>
img {
    max-width: 100%;
}

.avtive_footer {
    height: 24vw;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 31.2vw;
        height: 10.1333vw;
    }
}

.mask {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background: rgba(0, 0, 0, 0.6);
    z-index: 10000;
    .box {
      width: 100%;
      background: #fff;
      position: fixed;
      bottom: 0px;
      border-top-left-radius: 4vw;
      border-top-right-radius: 4vw;
      .top {
        display: flex;
        justify-content: space-between;
        padding: 4vw;
        span {
          font-size: 5vw;
          font-weight: 400;
          color: #17191c;
        }
      }
      .bottom {
        display: flex;
        padding: 10vw 0vw;
        .item {
          flex: 1;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          color: #17191c;
          font-size: 5vw;
        }
      }
    }
  }

// 客服中心
#app {
    background: #fff;
}

.help_center_content {
    width: 100%;
    background: #fff;
    border-radius: 5.3333vw 5.3333vw 0 0;
    margin-top: -4.2667vw;
    padding: 5.3333vw 4.2667vw;
    position: relative;
    z-index: 9;
    box-sizing: border-box;

    .help_center_item {
        border-radius: 1.3333vw;
        box-shadow: 0px 2px 20px 0px rgba(47,88,90,0.10);
        height: 18.6667vw;
        border-radius: 1.3333vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 4.2667vw;
        margin-bottom: 3.2vw;
        box-sizing: border-box;

        .help_center_left {
            display: flex;
            align-items: center;
            line-height: 1;
        }

        .help_center_icon {
            height: 7.4667vw;
            width: 7.4667vw;
            margin-right: 4.2667vw;
        }

        .help_center_right {
            height: 4.8vw;
            width: 4.8vw;
        }

        .help_center_tips {
            font-size: 3.2vw;
            color: #808388;
            margin-top: 2.1333vw;
        }

        .copy_text {
            color: #00A0AC;
            margin-left: 2.4vw;
            font-size: 3.2vw;
        }
    }
}

.page_footer {
    width: 100%;
    height: 24vw;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 0;

    img {
        width: 31.2vw;
        height: 10.1333vw;
    }
}

.back-icon {
    position: fixed;
    top: 3.7333vw;
    left: 3.7333vw;
    height: 5.3333vw;
    width: 5.3333vw;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mask_image {
    width: 80vw;
    height: 80vw;
}

.popup-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #EBEBEB;
    border-radius: 5.3333vw 5.3333vw 0 0;
    padding: 5.3333vw 4.2667vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 3.2vw;
    color: #808388;
    box-sizing: border-box;

    .popup-bottom-icon {
        height: 13.3333vw;
        width: 13.3333vw;
        margin-bottom: 0.8vw;
    }

    .popup-bottom-cancel {
        background-color: #fff;
        margin-top: 4vw;
        border-radius: 1.0667vw;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 4.2667vw;
        color: #202122;
        width: 91.2vw;
        height: 10.6667vw;
    }
}
</style>
